
import PerfectScrollbar from "perfect-scrollbar";
import { onMounted, PropType, ref, watch } from "vue";

interface ScrollingComponentSettingsInterface {
  handlers?: string[];
  wheelSpeed?: number;
  wheelPropagation?: boolean;
  swipeEasing?: boolean;
  minScrollbarLength?: number;
  maxScrollbarLength?: number;
  scrollingThreshold?: number;
  useBothWheelAxes?: boolean;
  suppressScrollX?: boolean;
  suppressScrollY?: boolean;
  scrollXMarginOffset?: number;
  scrollYMarginOffset?: number;
}

export default {
  name: "ScrollingComponent",
  props: {
    settings: {
      type: Object as PropType<ScrollingComponentSettingsInterface>,
      default: () => ({}),
    },
    name: {
      type: String,
      required: true
    },
    apdate: {
      type: Boolean,
      required: true
    }
  },
  setup(props: { settings: ScrollingComponentSettingsInterface, name: string, apdate: boolean }, c: any) {
    // default settings by specification
    //https://github.com/mdbootstrap/perfect-scrollbar#install
    const default_settings = ref({
      handlers: ["click-rail", "drag-thumb", "keyboard", "wheel", "touch"],
      wheelSpeed: 1,
      wheelPropagation: true,
      swipeEasing: true,
      minScrollbarLength: undefined,
      maxScrollbarLength: undefined,
      scrollingThreshold: 1000,
      useBothWheelAxes: false,
      suppressScrollX: false,
      suppressScrollY: false,
      scrollXMarginOffset: 0,
      scrollYMarginOffset: 0,
    });
    const root = ref(null);
    const ps = ref();

    // onBeforeMount(() => {});

    onMounted(() => {
      //setup an instance and overwirte with the settings passed by the developer
      const container = document.querySelector("#scroller-container-"+ props.name);
      ps.value = new PerfectScrollbar("#scroller-container-"+ props.name, {
        ...default_settings.value,
        ...props.settings,
      });
      //setup an instance and overwirte with the settings passed by the developer
      ps.value.update();
    });

    watch(() => props.apdate, (n, o) => {
      if (n != false && ps.value) {
        ps.value.update();
      }
    })

    return {};
  },
};
