import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "pr-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "search-results "
}
const _hoisted_4 = { class: "search-result nav-item clickable is-hidden-touch" }
const _hoisted_5 = { class: "search-result nav-item clickable is-hidden-desktop" }
const _hoisted_6 = {
  key: 1,
  class: "has-background-white has-text-centered has-text-danger p-2 mt-2"
}
const _hoisted_7 = {
  key: 2,
  class: "has-background-white has-text-centered has-text-danger p-2 mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResultEntityComponent = _resolveComponent("ResultEntityComponent")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$props.empty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          ($props.results.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("nav", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.results, (place, index) => {
                    return (_openBlock(), _createBlock(_component_router_link, {
                      key: index,
                      class: "router-link",
                      onClick: ($event: any) => ($setup.navigateOnClick(place.id)),
                      onMouseover: ($event: any) => ($setup.onMouseOver(place)),
                      onMouseout: ($event: any) => ($setup.onMouseOut(place)),
                      to: {
          name: 'MerchantDetails',
          params: {
            merchant_id: place.id,
          },
          query: {
            ..._ctx.$route.query,
            from_search: true
          }
        }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ResultEntityComponent, {
                          subtitle: place.address + ', ' + place.postcode + ' ' + place.city,
                          title: place.name,
                          "voucher-type": $props.voucherType,
                          icons: [$setup.icon_ind],
                          taglist: place.tagList,
                          "in-radius": undefined,
                          distance: place.distance,
                          "count-places": ++index,
                          "by-name": $setup.searchStore.getters.searchType == 'name',
                          focused: parseInt($setup.userStore.focusedMarker) === ++index
                        }, null, 8, ["subtitle", "title", "voucher-type", "icons", "taglist", "distance", "count-places", "by-name", "focused"])
                      ]),
                      _: 2
                    }, 1032, ["onClick", "onMouseover", "onMouseout", "to"]))
                  }), 128))
                ]),
                _createElementVNode("nav", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.results, (place, index) => {
                    return (_openBlock(), _createBlock(_component_router_link, {
                      key: index,
                      class: "router-link ",
                      onClick: ($event: any) => ($setup.navigateOnClick(place.id)),
                      to: {
          name: 'MerchantDetails',
          params: {
            merchant_id: place.id,
          },
          query: {
            ..._ctx.$route.query,
            from_search: true
          }
        }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ResultEntityComponent, {
                          subtitle: place.address + ', ' + place.postcode + ' ' + place.city,
                          title: place.name,
                          "voucher-type": $props.voucherType,
                          icons: [$setup.icon_ind],
                          taglist: place.tagList,
                          "in-radius": undefined,
                          distance: place.distance,
                          "count-places": ++index,
                          "by-name": $setup.searchStore.getters.searchType == 'name',
                          focused: parseInt($setup.userStore.focusedMarker) === ++index
                        }, null, 8, ["subtitle", "title", "voucher-type", "icons", "taglist", "distance", "count-places", "by-name", "focused"])
                      ]),
                      _: 2
                    }, 1032, ["onClick", "to"]))
                  }), 128))
                ])
              ]))
            : ($props.results.length == 0 && $props.ready)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('no.results')), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, " Loading ... "))
        ]))
      : _createCommentVNode("", true)
  ]))
}