
import LevelComponent from "@/components/level/LevelComponent.vue";
import LevelItemComponent from "@/components/level/LevelItemComponent.vue";
// import TooltipComponent from "@/components/elements/TooltipComponent.vue";
import IconsByImageCollection from "@/components/elements/IconsByImageCollection.vue";
import {PropType, inject, onMounted, Ref, ref} from "vue";
import {ENUMVoucherTypes} from "@/Interfaces/EnumVouchers";

export default {
  name: "ResultEntityComponent",
  components: {
    IconsByImageCollection,
    // TooltipComponent,
    LevelItemComponent,
    LevelComponent,
    // LevelComponent
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    taglist: {
      type: Array,
      required: false
    },
    icons: {
      type: Array as PropType<Array<ENUMVoucherTypes>>,
      required: true
    },
    countPlaces: {
      type: Number,
      required: false,
      default: undefined
    },
    distance: {
      type: [Number, String],
      required: false
    },
    inRadius: {
      type: Number,
      required: false,
      default: undefined
    },
    focused: {
      type: Boolean,
      required: false,
      default: false,
    },
    byName: {
      type: Boolean,
      required: true,
      default: false,
    },
    voucherType: {
      type: String,
      required: true,
      default: "emv",
    }
  },
  emits: ["mouseover-search-result"],
  setup(props: any) {
    //const searchStore:any = inject('searchStore');
    //const isSearchByName: boolean = props.byName;
    const isInMeters: Ref<boolean> = ref(false);
    const readableDistance: Ref<number> = ref(parseFloat(props.distance));

    onMounted(() => {
      const temp: Ref<number> = ref(0);

      if (parseFloat(props.distance) < 1) {
        isInMeters.value = true;
      }

      if ( typeof props.distance === "string") {
        temp.value = parseFloat(props.distance.replace(",", "."));
      } else {
        temp.value = parseFloat(props.distance);
      }

      if (parseFloat(props.distance) < 1) {
        readableDistance.value = temp.value * 1000;
      } else {
        readableDistance.value = temp.value;
      }
    });

    return {
      //isSearchByName,
      isInMeters,
      readableDistance
    }
  }
};
