
/* eslint-disable no-undef */
import {
  inject,
  onBeforeMount,
  onMounted,
  ref,
  Ref,
    computed,
  watch,
  SetupContext,
} from "vue";
import {ENUMVoucherTypes} from "@/Interfaces/EnumVouchers";
import {onBeforeRouteLeave, onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
import {useScrollingSettings} from "@/composables/ScrollingComposable";
import {usePlaces} from "@/composables/PlacesComposable";
import MainNavbar from "@/components/navigations/MainNavbar.vue";
import ScrollingComponent from "@/components/elements/ScrollingComponent.vue";
import TypeAheadAutocompleteComponent from "@/components/elements/TypeAheadAutocompleteComponent.vue";
import {useMapsComposable} from "@/composables/MapsComposable";
import HomeNavigationTabs from "@/components/tabs/HomeNavigationTabs.vue";
import SearchPage from "@/components/SearchPage.vue";
import Overlay from "@/components/elements/Overlay.vue";
import {Loader} from "@googlemaps/js-api-loader";
import {useI18n} from "vue-i18n";
import {getMarkerClusterer} from "@/reuseables/Clustering";
import app from "@/components/layouts/App.vue";

/**
 * Main interface used for the search model
 */
interface SearchModel {
  search_type: string | undefined;
  search_criteria: string | undefined;
}

interface PropsHome {
  voucherType?: number;
  category?: number | undefined;
  searchType?: string;
  searchCriteria: string;
  lang: string;
}

export default {
  components: {
    ScrollingComponent,
    //TypeAheadAutocompleteComponent,
    MainNavbar,
    HomeNavigationTabs,
    SearchPage,
    Overlay,
  },
  name: "Search",
  emits: ["mouseover-search-result", "select"],
  props: {
    voucherType: {
      type: Number,
      required: false,
      default: undefined,
    },
    category: {
      type: Number || undefined,
      required: false,
      default: undefined,
    },
    searchType: {
      type: String,
      required: false,
      default: undefined,
    },
    searchCriteria: {
      type: String,
      required: false,
      default: "",
    },
    lang: {
      type: String,
      required: true,
    },
  },
  async setup(props: PropsHome, context: SetupContext) {
    /**
     * Inject all the stores provided by the main app
     */
        const langStore: any = inject("langStore");

    const categoryStore: any = inject("categoryStore");
    const vouchersStore: any = inject("vouchersStore");
    const placeStore: any = inject("placeStore");
    const searchStore: any = inject("searchStore");
    const userStore: any = inject("userStore");
    const globalStore:any = inject("globalStore");
    /**
     * ------------------------------------------------------
     */

    const i18n = useI18n();
    const searchModel = ref();

    /**
     * Center of Belgium
     */
    const lat: Ref<any> = ref(50.5010789);
    const lng: Ref<any> = ref(4.4764595);
    const distance: Ref<number | undefined> = ref(300);

    const page_to_search: Ref<number> = ref(1);

    const shopname: Ref<string | undefined> = ref(undefined);
    const coords: any = ref();
    const gotResponse: Ref<boolean> = ref(false);
    const isEmpty: Ref<boolean> = ref(true);

    // language handlers
    // eslint-disable-next-line vue/no-setup-props-destructure
    const initLang = props.lang; // used for map language, which will correspond to the language on load.
    langStore.setters.setCurrentLang(props.lang)

    //# load the composable
    const {fetchPlaces, getItems, getFindings, getCoordsOf} = usePlaces();
    const router = useRouter();
    const route = useRoute();

    // The main storage DIV Ref where the map would be placed in
    const myMapRef = ref();
    const shouldUpdateScrollBar = ref(false);

    const searchResults: Ref<Array<[]>> = ref([]);
    const searchVoucherType: Ref<string> = ref("");

    /**
     * Variable to store error data in case API calls fail
     */
    const error = ref(false);

    const isLoading = ref(false);
    const mapHeight = ref('100vh');
    const listHeight = ref('100%');
    const NavBar: any = ref(null);
    const NavTabs: any = ref(null);
    const resultCount = ref(0);


    const {horizontal_settings, vertical_settings} = useScrollingSettings();
    //# map composable
    const {selectMarker, highlightMarker, focusOutMarker, prepareMarkers} = useMapsComposable();

    const loadGoogle: any = async (): Promise<void> => {
      page_to_search.value = 1;

      var google = placeStore.getters.google;

      if (!google) {
        const loaderRef = new Loader({
          apiKey: process.env.VUE_APP_MAPS_API_KEY,
          libraries: ["places"],
          language: initLang,
        });

        await loaderRef.load().then((google) => {
          placeStore.setters.setGoogle(google);
          placeStore.setters.setBoundsRef(new google.maps.LatLngBounds());
          console.log(google)
          return google;
        })
      }
    }

    const populateSearchSidebar = async (page_number: number,) => {
      // Populate results
      let append = false
      if (page_number > 1) {
        append = true;
      }
      await fetchPlaces(
          categoryStore.getters.getSelected,
          searchVoucherType.value,
          shopname.value,
          lat.value,
          lng.value,
          distance.value,
          'full',
          page_number,
          20,
          append
      ).finally(async () => {
        error.value = false;
        searchResults.value = placeStore.getters.getItems;
        gotResponse.value = true;
        isEmpty.value = false;
        shouldUpdateScrollBar.value = true;
        shouldUpdateScrollBar.value = false;

      }).catch((err) => {
        // Show generic error message
        console.log('ERROR ', err);
        error.value = true;
        isLoading.value = false;
        gotResponse.value = true;
        isEmpty.value = false;
      });
    }

    /**
     *
     * @param markers
     * @param clusterSize
     */
    const makeMap = async (markers: Array<any>, clusterSize: number, voucherType: any, center?: any, zoomToExtent?: boolean) => {
      const google = await placeStore.getters.google;

      if (!center || center.lat === undefined || center.lng === undefined) {
        center = {lat: 50.8476, lng: 4.3572};
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const map = new google.maps.Map(myMapRef.value, {
        mapId: process.env.VUE_APP_MAP_ID,
        center: center,
        zoom: 5,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        disableDefaultUI: false,
      });

      placeStore.actions.makeMap(map);

      await placeStore.actions.resetBounds();
      await placeStore.setters.setMarkersRef(null);

      if (markers) {
        prepareMarkers(markers, map, placeStore.getters.getBoundsRef, voucherType, undefined);
      } else {
        // console.log('there are no markers to display.')
      }

      ;

      // set a range zoom by all the markers in the map
      if (placeStore.getters.getBoundsRef && props.searchCriteria && markers.length) {
        map.fitBounds(placeStore.getters.getBoundsRef);

      }
      if (zoomToExtent) {
        map.fitBounds(placeStore.getters.getBoundsRef);
      } else {
        map.setZoom(15);
      }
      isLoading.value = false
    };

    const render = async () => {
      console.log('Start rendering search view')
      i18n.locale.value = props.lang;
      gotResponse.value = false;
      searchResults.value = [];
      searchVoucherType.value = vouchersStore.actions.findElement(parseInt(vouchersStore.getters.getSelected))?.ico;

      isLoading.value = true;

      await loadGoogle();

      if (route.params.search_type) {
        await searchStore.setters.setSearchType(route.params.search_type);
      }

      // # what to be done if there is a search criteria per name
      if (searchStore.getters.searchType == "name") {
        shopname.value = searchStore.getters.searchCriteria, searchStore.getters.searchType;
        distance.value = undefined;
        lat.value = undefined;
        lng.value = undefined;

      } else {
        shopname.value = undefined;
        distance.value = 20;
         const searchString: any = searchStore.getters.searchCriteria;

        if (searchStore.getters.searchType == "location") {

          // # what to be done if there is a search criteria per location
          coords.value = await getCoordsOf(searchStore.getters.searchCriteria, searchStore.getters.searchType, google);
        if (coords.value.lat != 0 && coords.value.lng != 0) {
          lat.value = coords.value.results[0].geometry.location.lat();
          lng.value = coords.value.results[0].geometry.location.lng();
          //console.log("finished setting coords")
        } else {
          // console.log('didnt get a coordinate for this place..')
          // Wrong input - Unrecognized city name or postal code
          await placeStore.actions.clearFindings();
          await makeMap([], 1, vouchersStore.getters.getSelected);
          gotResponse.value = true;
          isLoading.value = false;
          isEmpty.value = false;
          return;
        }

        }
        else if (searchStore.getters.searchType =="nearby") {
          var coordList = searchString.split(',')
          if (coordList.length == 2) {
            lat.value = parseFloat(coordList[0]) //Todo some safety measures for wrong input
            lng.value = parseFloat(coordList[1])
          }

          }


      }

      // Populate Map
      await fetchPlaces(
          categoryStore.getters.getSelected,
          searchVoucherType.value,
          shopname.value,
          lat.value,
          lng.value,
          distance.value,
          'light',
      ).finally(async () => {
        error.value = false;
        isEmpty.value = false;
        shouldUpdateScrollBar.value = true;
        if (getItems().length < 1) {
          //console.log("no items found..")
          resultCount.value = 0
          await makeMap(getFindings() || getItems(), 1, vouchersStore.getters.getSelected);

        } else {
          //onsole.log("Places retrieved -> start mapping")
          resultCount.value = getItems().length

          await makeMap(getFindings() || getItems(), 1, vouchersStore.getters.getSelected,
              {'lat': lat.value, 'lng': lng.value}, true);
        }
      }).catch((err) => {
        // Show generic error message
        console.log('ERROR ', err);
        error.value = true;
        isLoading.value = false;
        gotResponse.value = true;
        isEmpty.value = false;
        resultCount.value = 0

      });

      await populateSearchSidebar(page_to_search.value)
      page_to_search.value++

    };


    /**
     * An Event handler which invokes a selection
     * Select the marker by provided a place record
     *
     * @param place
     */
    const onSearchHover = (place: Record<string, any>) => {
      highlightMarker(place.id, vouchersStore.actions.findElement(parseInt(vouchersStore.getters.getSelected))?.ico);
    };

    /**
     *
     */
    const onSearchLeave = (place: Record<string, any>) => {
      focusOutMarker(place.id, vouchersStore.actions.findElement(parseInt(vouchersStore.getters.getSelected))?.ico);
    };

    /**
     * Remove Results
     */
    const removeResults = (): void => {
      searchResults.value = [];
      shouldUpdateScrollBar.value = true;
      isEmpty.value = true;
    }

    /**
     * What to be done when the search behaviour is being triggered
     * @param searchModel
     */
    const onEnter = async (searchModel: { search_type?: string, search_criteria: string }) => {

      isLoading.value = true;
      shouldUpdateScrollBar.value = false;

      await render();

      return;
    }

    onBeforeMount(async () => {
      //console.log('before mount search')
      if (route.params.search_type) {
        //console.log('setting search type to route.params.search_type ' + route.params.search_type )
        await searchStore.setters.setSearchType(route.params.search_type);
      }

      if (!searchStore.getters.searchCriteria && route.params.search_criteria) {
        //console.log('setting criteria to route.params.search_criteria ' + route.params.search_criteria)
        await searchStore.setters.setSearchCriteria(route.params.search_criteria);
      }

      if (!vouchersStore.getters.getSelected && route.query.voucher_type) {
        //console.log('setting selected type to  ' + route.query.voucher_type)
        await vouchersStore.setters.setSelected(route.query.voucher_type)
      }

      if (!categoryStore.getters.getSelected && route.query.category) {
        //console.log('setting selected cat to  ' + route.query.category)
        await categoryStore.setters.setSelected(route.query.category);
      }

      if (langStore.getters.getCurrentLang != props.lang) {
        await langStore.setters.setCurrentLang(props.lang);
      }
    });

    onMounted(async () => {
      const NavHeight: number = NavTabs.value.$el.clientHeight + NavBar.value.$el.clientHeight + 1;

      mapHeight.value = 'calc(100vh - ' + NavHeight + 'px)';
      listHeight.value = 'calc(100vh - ' + (NavHeight + 60) + 'px)';

      if (route.params.search_type) {
        await searchStore.setters.setSearchType(route.params.search_type);
      }

      if (!searchStore.getters.searchCriteria && route.params.search_criteria) {
        await searchStore.setters.setSearchCriteria(route.params.search_criteria);
      }

      if (!vouchersStore.getters.getSelected && route.query.voucher_type) {
        await vouchersStore.setters.setSelected(route.query.voucher_type)
      }

      if (!categoryStore.getters.getSelected && route.query.category) {
        await categoryStore.setters.setSelected(route.query.category);
      }

      await render();
    });


    watch(() => route.query, async (n, o) => {
      if (route.name == "Search") {
        await render();
      }
    });


    const onScroll = (e: any) => {
      const {scrollTop, offsetHeight, scrollHeight} = e.target
      if ((scrollTop + offsetHeight) >= scrollHeight - 1000) {
        // this 1000 is added to trigger the reload slightly before reaching the bottom. This was needed to make it work on mobile.
        //console.log('bottom! loading page' + page_to_search.value)
        populateSearchSidebar(page_to_search.value)
        page_to_search.value++
      }
    }
    onBeforeRouteLeave((to, from) => {
      const map = placeStore.getters.getMap;
      const mark = placeStore.getters.getMarkerHighlight
      // if (mark) {
      //   mark.setMap(null)
      //   placeStore.setters.setMarkerHighlight(null);
      // }
      if (map !== undefined) {

        if (map.getZoom() !== undefined) {
          // this.config.globalProperties
          globalStore.Zoom.value = map.getZoom()
          globalStore.Center.value = map.getCenter()
          globalStore.Bounds.value = map.getBounds()
        }
      }
    });


    return {
      vouchersStore,
      categoryStore,
      searchStore,
      placeStore,
      langStore,
      userStore,
      horizontal_settings,
      vertical_settings,
      myMapRef,
      searchModel,
      error,
      resultCount,
      isLoading,
      gotResponse,
      isEmpty,
      onEnter,
      render,
      ENUMVoucherTypes,
      selectMarker,
      mapHeight,
      listHeight,
      NavTabs,
      NavBar,
      onSearchHover,
      onSearchLeave,
      removeResults,
      onScroll,
      searchResults,
      shouldUpdateScrollBar,
      searchVoucherType
    };
  },
};
