
import {inject, watch, ref} from "vue";
import ResultEntityComponent from "@/components/elements/ResultEntityComponent.vue";
import {useRoute, useRouter} from "vue-router";

export default {
  name: "SearchPage",
  components: {
    ResultEntityComponent,
  },
  emits: ["mouseoverSearchResult", "mouseover-search-result", "mouseoutSearchResult", "mouseout-search-result"],
  props: {
    results: {
      type: Array,
      required: true,
      default: null,
    },
    ready: {
      type: Boolean,
      required: true,
      default: false,
    },
    empty: {
      type: Boolean,
      required: true,
      default: true,
    },
    voucherType: {
      type: String,
      required: true,
      default: "emv",
    }
  },
  setup(props: any, { emit }: any) {
    const userStore: any = inject("userStore");
    const searchStore: any = inject("searchStore");
    const vouchersStore: any = inject("vouchersStore");
    const route = useRoute();
    const router = useRouter();

    const icon_ind : any = ref(route.query.voucher_type);

    const onMouseOver = (place: any, index: number) => {
      emit("mouseover-search-result", place);
    };

    const onMouseOut = (place: any, index: number) => {
      emit("mouseout-search-result", place);
    };

    const navigateOnClick = (id: string) => {
      console.log('navigate on click to ' + id)
      router.push({
          name: 'MerchantDetails',
          params: {
            merchant_id: id,
          },
          query: {
            ...route.query,
            from_search: 'true'
          }
        })
    }

    watch(() => vouchersStore.getters.getSelected, (n, o) => {
      icon_ind.value = n;
    });

    return {
      userStore,
      searchStore,
      onMouseOver,
      onMouseOut,
      navigateOnClick,
      icon_ind
    };
  },
};
