import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hero is-red is-white" }
const _hoisted_2 = { class: "hero-body is-align-items-stretch p-0" }
const _hoisted_3 = { style: {"height":"50px","display":"flex","justify-content":"space-between"} }
const _hoisted_4 = {
  class: "tabs is-toggle mb-0 mr-8 is-inline-flex",
  style: {"align-items":"center"}
}
const _hoisted_5 = { class: "router-link-exact-active" }
const _hoisted_6 = ["href", "onClick"]
const _hoisted_7 = {
  key: 0,
  class: "mb-0 mr-4 is-inline-flex",
  style: {"align-items":"center"}
}
const _hoisted_8 = {
  key: 0,
  class: "has-background-white has-text-centered has-text-danger p-2 mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNavbar = _resolveComponent("MainNavbar")!
  const _component_HomeNavigationTabs = _resolveComponent("HomeNavigationTabs")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SearchPage = _resolveComponent("SearchPage")!
  const _component_ScrollingComponent = _resolveComponent("ScrollingComponent")!
  const _component_Overlay = _resolveComponent("Overlay")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MainNavbar, { ref: "NavBar" }, null, 512),
    _withDirectives(_createVNode(_component_HomeNavigationTabs, {
      key: 'nav-filters'+_ctx.$i18n.locale,
      ref: "NavTabs",
      "is-home": true,
      "voucher-type": _ctx.$route.query?.voucher_type,
      category: _ctx.$route.query?.category
    }, null, 8, ["voucher-type", "category"]), [
      [_vShow, _ctx.$route.name != 'MerchantDetails']
    ]),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["column is-3-desktop is-12-tablet is-12-mobile is-absolute", {
        'has-background-white':  $setup.searchStore.getters.isStartedSearching,
      }]),
          style: _normalizeStyle({height: $setup.mapHeight, zIndex: 9998, paddingBottom: 0})
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_router_link, {
                to: { name: 'Home', query: {voucher_type: _ctx.$route.query?.voucher_type, category: _ctx.$route.query?.category} },
                "active-class": "is-active",
                custom: ""
              }, {
                default: _withCtx(({ href, navigate }) => [
                  _createElementVNode("li", _hoisted_5, [
                    _createElementVNode("a", {
                      href: href,
                      onClick: ($event: any) => {navigate; $setup.removeResults}
                    }, _toDisplayString(_ctx.$t('results.close')), 9, _hoisted_6)
                  ])
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            ($setup.resultCount.valueOf() > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($setup.resultCount.valueOf()) + " " + _toDisplayString(_ctx.$t('results.amount')), 1))
              : _createCommentVNode("", true)
          ]),
          ($setup.error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("technical.issue")), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_ScrollingComponent, {
            class: "p-0 is-flex-direction-column is-flex is-relative",
            style: _normalizeStyle({height: $setup.listHeight.value}),
            apdate: $setup.shouldUpdateScrollBar,
            name: "type-ahead-results",
            settings: $setup.vertical_settings,
            onScroll: $setup.onScroll
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createVNode(_component_SearchPage, {
                  results: $setup.searchResults,
                  ready: $setup.gotResponse,
                  empty: $setup.isEmpty,
                  "voucher-type": $setup.searchVoucherType,
                  onMouseoverSearchResult: $setup.onSearchHover,
                  onMouseoutSearchResult: $setup.onSearchLeave
                }, null, 8, ["results", "ready", "empty", "voucher-type", "onMouseoverSearchResult", "onMouseoutSearchResult"])
              ])
            ]),
            _: 3
          }, 8, ["style", "apdate", "settings", "onScroll"])
        ], 6),
        _createElementVNode("div", {
          id: "map",
          ref: "myMapRef",
          class: _normalizeClass(["column is-8-tablet is-12-mobile p-0 is-pulled-right", {
            'is-9-desktop': _ctx.$route.name == 'Search'|| _ctx.$route.name == 'MerchantDetails',
            'is-12-desktop': (_ctx.$route.name == 'Home')}]),
          style: _normalizeStyle({height: $setup.mapHeight})
        }, null, 6)
      ])
    ]),
    ($setup.isLoading)
      ? (_openBlock(), _createBlock(_component_Overlay, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}