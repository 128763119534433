import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps({
    id: 'scroller-container-' + $props.name,
    ref: "root"
  }, _ctx.$attrs), [
    _renderSlot(_ctx.$slots, "default")
  ], 16, _hoisted_1))
}